const button = {
  open: document.querySelector(".js-hum-open") as HTMLElement,
  close: document.querySelector(".js-hum-close") as HTMLElement
}
const menu = document.querySelector(".l-header__nav") as HTMLElement;

button.open.addEventListener("click", ()=>{
  menu.classList.add("l-header__nav--open");
})

button.close.addEventListener("click", ()=>{
  menu.classList.remove("l-header__nav--open");
})
