const scrollButton: HTMLElement | any = document.querySelector(".c-img__scroll__top");
const scrollUndedrButton: HTMLElement | any = document.querySelector(".c-img__scroll");
let topSection: HTMLElement | any = document.querySelector(".l-section__mv");
let footerTop: HTMLElement | any = document.querySelector("footer") as HTMLElement;
footerTop = footerTop.offsetTop;

var smoothScrollType: any;
if('scrollBehavior' in document.documentElement.style) {
  //smoothScrollType = smoothScroll;
  smoothScrollType = oldTypeSmoothScroll;
} else {
  smoothScrollType = oldTypeSmoothScroll;
}

const point: number = 200;
window.addEventListener("scroll", () => {
  const scroll: number = window.scrollY;
  const scrollBottom: number = window.scrollY + window.innerHeight;
  if (footerTop < scrollBottom) {
    scrollButton.classList.add("c-img__scroll__top--bottom");
  } else {
    scrollButton.classList.remove("c-img__scroll__top--bottom");
  }
  if (point < scroll) {
    scrollButton.classList.add("c-img__scroll__top--active");
  } else {
    scrollButton.classList.remove("c-img__scroll__top--active");
  }
})

scrollButton.addEventListener("click", (event: any)=>{
  oldTypeSmoothScroll(event);
});

if (scrollUndedrButton) {
  scrollUndedrButton.addEventListener("click", (event: any)=> {
    oldTypeSmoothScroll(event);
  });
}

//animation

window.addEventListener("scroll", ()=>{
  ScrollAnim();
  SplitAnim();
});
window.addEventListener("load", ()=>{
  window.scrollTo({
    top: 1,
  });
})

const SplitAnim = () => {
  let splitPos = document.querySelectorAll(".js-split") as NodeListOf<HTMLElement>;
  const scroll = window.scrollY;
  const windowHeight = window.innerHeight;
  const splitPosition = Array.from(splitPos).filter((elm) => {
    return !elm.classList.contains("js-split--active");
  });
  splitPosition.forEach((elm, index) => {
    setTimeout(()=>{
      const elmPos = elm.getBoundingClientRect().top + window.pageYOffset;
      console.log(elmPos)
      if (scroll > elmPos - windowHeight + 200){
        elm.classList.add("js-split--active");
      } else {
        elm.classList.remove("js-split--active");
      }
      const splitPosActive = document.querySelectorAll(".js-split--active");
      splitPosActive.forEach(elm => {
        const texts = elm.querySelectorAll(".js-split-text");
        texts.forEach((text, index) => {
          setTimeout(()=>{
            text.classList.add("js-split-text--active");
          }, 40 * index);
        })
      })
    }, 150 * index);
  }
)}

const ScrollAnim = () => {
  const scrollAnimElm = document.querySelectorAll(".js-scroll-anim") as NodeListOf<HTMLElement>;
  const scroll = window.scrollY;
  const windowHeight = window.innerHeight;
  scrollAnimElm.forEach((elm) => {

    const elmPos = elm.offsetTop;
    if (scroll > elmPos - windowHeight + 200) {
      elm.classList.add("js-scroll-anim--active");
    } else {
      elm.classList.remove("js-scroll-anim--active");
    }
  })
}

function smoothScroll( pos: number ) {
  window.scroll({top: pos, behavior: 'smooth'});
}

function oldTypeSmoothScroll(e: any) {
  // スクロール速度の設定
  var scrollSpeed = 300;
  var timerStep = 20;
  // 遷移先の要素
  console.log(e)
  var pagelinkId = e.target.attributes.href.value;
  var pagelinkSection = document.querySelector(pagelinkId);
  // スクロール先の位置
  var scrollPosition = window.pageYOffset;
  var sectionPosition = pagelinkSection.getBoundingClientRect().top;
  console.log("sectionPosition: " + sectionPosition)
  var goalPosition = scrollPosition + sectionPosition;
  // スクロール量
  var scrollStep = sectionPosition / (scrollSpeed / timerStep);
  console.log("scrollStep:" + scrollStep);
  console.log("goalPosition:" + goalPosition);
  var currentScroll = scrollPosition;
  console.log("currentScroll: " + currentScroll);

  // スクロールの実行
  var smoothScrollTimer = setInterval(function() {
    // スクロール完了時
    if(Math.sign(scrollStep) == 1) {
      if(currentScroll >= goalPosition) {
        // スクロール位置を完了位置に設定
        window.scrollTo(0, goalPosition);
        // タイマーのクリア
        clearInterval(smoothScrollTimer);
      } else {
        currentScroll += scrollStep;
        window.scrollTo(0, currentScroll);
      }
    } else {
      if(currentScroll <= goalPosition) {
        // スクロール位置を完了位置に設定
        window.scrollTo(0, goalPosition);
        // タイマーのクリア
        clearInterval(smoothScrollTimer);
      } else {
        currentScroll += scrollStep;
        window.scrollTo(0, currentScroll);
      }
    }
  }, timerStep);
}


//font animation

